import { Button } from "../ui/button"
import { DiscordIcon } from "./icons/DiscordIcon"
import { MagicEdenIcon } from "./icons/MagicEdenIcon"
import { TwitterIcon } from "./icons/TwitterIcon"

export const Socials = () => {
    return (
        <div className="flex gap-2 fill-white">
            <Button variant="link" asChild>
                <a href="https://x.com/ord_ephemera" target="_blank" rel="noopener noreferrer">
                    <TwitterIcon />
                    <span className="sr-only">Twitter</span>
                </a>
            </Button>
            <Button variant="link" asChild>
                <a href="https://magiceden.io/ordinals/marketplace/ephemera" target="_blank" rel="noopener noreferrer">
                    <MagicEdenIcon/>
                    <span className="sr-only">Magic Eden</span>
                </a>
            </Button>
            <Button variant="link" asChild>
                <a href="https://discord.com/channels/1193686156051746846/1263549140907065414" target="_blank" rel="noopener noreferrer">
                    <DiscordIcon />
                    <span className="sr-only">Discord</span>
                </a>
            </Button>
        </div>
    )
}