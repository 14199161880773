import { useRef, useEffect } from 'react';
import { Canvas, useThree } from "@react-three/fiber";
import Starfield, { StarfieldRef } from "./3d/effects/StarField";
import { OrthographicCamera } from "@react-three/drei";
import { OrthographicCamera as OrthographicCameraType } from 'three';


const isOrthographic = (camera: any): camera is OrthographicCameraType => {
    return camera.type === 'OrthographicCamera';
}

const CameraController = () => {
    const { camera, size } = useThree();
    
    useEffect(() => {
      const aspect = size.width / size.height;
      const frustumSize = 2;
      
      if (!isOrthographic(camera)) {
        return;
      }
      
      if (aspect > 1) {
        // Landscape orientation
        camera.top = frustumSize / 2;
        camera.bottom = -frustumSize / 2;
        camera.left = -(frustumSize * aspect) / 2;
        camera.right = (frustumSize * aspect) / 2;
      } else {
        // Portrait orientation
        camera.left = -frustumSize / 2;
        camera.right = frustumSize / 2;
        camera.top = frustumSize / (2 * aspect);
        camera.bottom = -frustumSize / (2 * aspect);
      }
      
      camera.updateProjectionMatrix();
    }, [size, camera]);
  
    return null;
  };
  
  export const StarfieldBackground = () => {
      const starfieldRef = useRef<StarfieldRef>(null);
      return (
          <div className="fixed inset-0 w-[max(100vw,100vh)] h-[max(100vw,100vh)] overflow-hidden" style={{ zIndex: -1 }}>
              <Canvas
                  gl={{ antialias: false, alpha: true }}
                  style={{ position: 'absolute', width: '100%', height: '100%' }}
              >
                  <color attach="background" args={['#000000']} />
                  <OrthographicCamera 
                      makeDefault 
                      near={0.1}
                      far={1000}
                      position={[0, 0, 1]}
                  />
                  <CameraController />
                  <Starfield ref={starfieldRef} />
              </Canvas>
          </div>
      );
  };