const XverseIcon = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 128 128"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <rect width="128" height="128" fill="#12100F" rx="26.839" />
    <g transform="translate(28,28) scale(0.25)">
      <path
        d="M298.418 293.613V239.294C298.418 237.14 297.562 235.086 296.036 233.56L64.9159 2.43899C63.3897 0.912729 61.3354 0.0568848 59.1816 0.0568848H4.86405C2.1824 0.0568848 0 2.23929 0 4.92093V55.3873C0 57.5412 0.855845 59.5951 2.3821 61.1214L85.3421 144.081C87.2391 145.979 87.2391 149.06 85.3421 150.957L1.42641 234.872C0.513507 235.785 0 237.026 0 238.31V293.613C0 296.292 2.1824 298.477 4.86405 298.477H95.6263C98.308 298.477 100.49 296.292 100.49 293.613V261.033C100.49 259.749 101.004 258.508 101.917 257.595L146.934 212.577C148.831 210.68 151.912 210.68 153.81 212.577L237.34 296.109C238.866 297.635 240.92 298.491 243.074 298.491H293.54C296.222 298.491 298.404 296.307 298.404 293.627L298.418 293.613Z"
        fill="#DDDDDD"
      />
      <path
        d="M177.345 71.9901H222.805C225.5 71.9901 227.697 74.1868 227.697 76.8827V122.342C227.697 126.707 232.975 128.89 236.056 125.794L298.418 63.332C299.33 62.4189 299.846 61.1781 299.846 59.88V5.1772C299.846 2.48131 297.661 0.284639 294.952 0.284639L239.436 0.213318C238.139 0.213318 236.898 0.726824 235.97 1.63973L173.879 63.6314C170.798 66.7124 172.98 71.9901 177.331 71.9901H177.345Z"
        fill="#EE7A30"
      />
    </g>
  </svg>
);

export default XverseIcon;