import { useAccountStore } from "@/stores/accountStore"
import { useQuery } from "@tanstack/react-query"
import { Circle } from "lucide-react"
import { Tooltip, TooltipTrigger } from "../ui/tooltip"
import { TooltipContent } from "@radix-ui/react-tooltip"

export const WebsocketIndicator = () => {
    const isWebSocketActive = useAccountStore(state => state.isWebSocketActive)
    const numConnected = useQuery({ queryKey: ['connectedUsers'] }).data;
    return (
        <Tooltip>
            <TooltipTrigger>
                <div className="flex gap-2">
                    <WebsocketDot active={isWebSocketActive} />
                    <span className="text-sm text-muted-foreground">
                        {isWebSocketActive ? 'Active' : 'Connecting'}
                    </span>
                    <NumConnected />
                </div>
            </TooltipTrigger>

            <TooltipContent side="top" align="center">
                <div className="text-sm text-muted-foreground p-2 m-2 bg-primary-foreground/50 rounded-md">
                    {isWebSocketActive
                        ? `${numConnected} users connected.`
                        : 'Connecting to server...'
                    }
                </div>
            </TooltipContent>
        </Tooltip>
    )
}

const WebsocketDot = ({ active }: { active: boolean }) => {
    return (
        <Circle className={`h-3 my-auto w-3 ml-2 ${active ? 'fill-green-500' : 'fill-zinc-400/50'} `} />
    )
}

const NumConnected = () => {
    const numConnected = useQuery({ queryKey: ['connectedUsers'] }).data as number;
    return (
        <span className="text-sm text-neutral-500">{numConnected}</span>
    )
}