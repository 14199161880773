import {
    DropdownMenu,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { Cog } from "lucide-react";
import { AudioToggle } from "./AudioToggle";
import { DropdownMenuContent } from "@radix-ui/react-dropdown-menu";
import { Button } from "../ui/button";
import { useEffect, useRef } from "react";
import { useSettingsStore } from "@/stores/settingsStore";

export const Settings = () => {
    const isSettingsMenuOpen = useSettingsStore(state => state.isSettingsMenuOpen);
    const openSettingsMenu = useSettingsStore(state => state.openSettingsMenu);
    const closeSettingsMenu = useSettingsStore(state => state.closeSettingsMenu);
    const toggleOpen = () => (isSettingsMenuOpen ? closeSettingsMenu() : openSettingsMenu());
    const settingsMenuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (isSettingsMenuOpen) {
                closeSettingsMenu();
            }
        };

        const handleOutsideClick = (event: MouseEvent) => {
            if (settingsMenuRef.current && !settingsMenuRef.current.contains(event.target as Node)) {
                closeSettingsMenu();
            }
        }


        if (isSettingsMenuOpen) {
            window.addEventListener('scroll', handleScroll);
            document.addEventListener('mousedown', handleOutsideClick);
            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [isSettingsMenuOpen, closeSettingsMenu]);

    return (
        <DropdownMenu modal={false} open={isSettingsMenuOpen} >
            <div ref={settingsMenuRef}>
                <DropdownMenuTrigger asChild={true}>
                    <Button
                        onClick={toggleOpen}
                        size='icon'
                        variant='ghost'
                        className="flex items-center gap-2 text-primary"
                        aria-label="Settings"
                    >
                        <Cog className="w-4 h-4" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="bg-secondary p-4 shadow-md">
                    <div className="w-full pb-1 text-center text-lg ">
                        Settings
                    </div>
                    <DropdownMenuSeparator className="bg-muted-foreground" />
                    <DropdownMenuItem>
                        <DropdownMenuLabel>
                            Toggle Audio:
                        </DropdownMenuLabel>
                        <AudioToggle />
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </div>
        </DropdownMenu>
    )
}