import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { mapAddressToConstellation, mapAddressToGradient } from '@/lib/usernameUtils';
import { useAccountStatusQuery, useDisconnectWalletMutation } from '@/queries/account';
import { Network } from '@/schemas/bitcoin';
import { useAccountStore } from '@/stores/accountStore';
import { useSettingsStore } from "@/stores/settingsStore";
import { Link } from "@remix-run/react";
import { motion } from 'framer-motion';
import { CircleUser } from "lucide-react";
import { useEffect, useRef } from 'react';
import { ConnectDialog } from "../connectDialog/connectDialog";


const MotionButton = motion(Button);

const ConnectButton = ({ network }: { network: Network }) => {
    const { isConnected, ordinalAddress } = useAccountStore();
    const disconnectWalletMutation = useDisconnectWalletMutation();
    const accountMenuRef = useRef<HTMLDivElement>(null);
    const openConnectDialog = useSettingsStore(state => state.openWalletDialog);

    const { data: accountStatus } = useAccountStatusQuery();
    const email = accountStatus?.email;
    const username = ordinalAddress ? mapAddressToConstellation(ordinalAddress) : '';
    const isAccountMenuOpen = useSettingsStore(state => state.isAccountMenuOpen);
    const openAccountMenu = useSettingsStore(state => state.openAccountMenu);
    const closeAccountMenu = useSettingsStore(state => state.closeAccountMenu);
    const toggleOpen = () => (isAccountMenuOpen ? closeAccountMenu() : openAccountMenu());
    const grad = ordinalAddress ? mapAddressToGradient(ordinalAddress) : { angle: '0deg', stop1: '#000000', stop2: '#000000' };
    const gradstyle = {
        backgroundImage: `linear-gradient(
            ${grad.angle},
            ${grad.stop1},
            ${grad.stop2}
        )`,
        transition: 'opacity 0s',
    };

    useEffect(() => {

        const handleOutsideClick = (event: MouseEvent) => {
            if (accountMenuRef.current && !accountMenuRef.current.contains(event.target as Node)) {
                closeAccountMenu();
            }
        };

        const handleScroll = () => {
            if (isAccountMenuOpen) {
                closeAccountMenu();
            }
        };

        if (isAccountMenuOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
            window.addEventListener('scroll', handleScroll);
            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [isAccountMenuOpen, closeAccountMenu]);

    const handleDisconnectWallet = () => {
        closeAccountMenu();
        disconnectWalletMutation.mutate();
    };

    return (
        <>
            <DropdownMenu modal={false} open={isAccountMenuOpen} >
                <div ref={accountMenuRef}>
                    <DropdownMenuTrigger asChild={true}>
                        <MotionButton
                            layout
                            layoutId='dropdown-button'
                            key='dropdown-button'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            style={isConnected ? gradstyle : {}}
                            size="icon"
                            onClick={isConnected ? toggleOpen : openConnectDialog}
                            className={isConnected
                                ? `justify-center transition-all duration-200 items-center mx-0 flex w-fit px-4 opacity-100 hover:opacity-50`
                                : `text-base w-full transition-all duration-200 my-button  bg-transparent px-4 hover:bg-transparent`
                            }
                        >
                            {isConnected ? (
                                <>
                                    <CircleUser className="h-[18px] w-6 mr-[2px]" />
                                    {username}
                                    <span className="sr-only">Toggle user menu</span>
                                </>
                            ) : (
                                <span>Connect</span>
                            )}
                        </MotionButton>
                    </DropdownMenuTrigger>

                    <DropdownMenuContent align="end">
                        {username &&
                            <>
                                <div
                                    style={gradstyle}
                                    className="text-xs text-white flex w-[calc(100%) + 0.1rem] justify-center bg-muted p-2 -m-1">
                                    {username}
                                    {network != 'mainnet' && (<sup className="pl-1 pt-2">{network.toUpperCase()}</sup>)}
                                </div>
                                <DropdownMenuSeparator />
                            </>
                        }
                        {ordinalAddress && (
                            <>
                                <DropdownMenuItem className="w-full justify-center">
                                    <Link to={`/account`}>
                                        Account
                                    </Link>
                                </DropdownMenuItem>
                                {!email &&
                                    <DropdownMenuItem className="w-full justify-center">
                                        <Link to={`/account/#email`}>
                                            Register Email
                                        </Link>
                                    </DropdownMenuItem>
                                }
                            </>
                        )}
                        <DropdownMenuSeparator />
                        <DropdownMenuItem className="w-full justify-center" onClick={handleDisconnectWallet}>
                            Logout
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </div>
            </DropdownMenu>
            <ConnectDialog />
        </>
    );
};

export default ConnectButton;
