import { useTheme } from "next-themes"
import { Toaster as Sonner } from "sonner"

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = "system" } = useTheme()

  return (
    <Sonner
      position="bottom-right"
      visibleToasts={3}
      gap={140}
      expand={true}
      offset={0}
      theme={theme as ToasterProps["theme"]}
      className="toaster group"
      toastOptions={
        {
          classNames: {
            toast: "group  group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
            description: "group-[.toast]:text-muted-foreground",
            closeButton: "group-[.toast]:text-muted-foreground bg-gray-200 hover:bg-gray-300",
          },
        }}
      {...props}
    />
  )
}

export { Toaster }
