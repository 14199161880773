import { motion } from 'framer-motion';
import { Settings } from './Settings';
import { WebsocketIndicator } from './WebsocketIndicator';
import { Socials } from './Socials';
import { Copyright } from 'lucide-react';

export function BottomBar() {

    return (
        <motion.div
            layout
            layoutRoot
            layoutId='bottombar'
            // shadow above the bottom bar
            style={{ boxShadow: '0px 0px 10px 4px rgba(0,0,0,0.04)' }}
            key='bottombar' className="max-w-[100vw] 
            bg-muted border border-muted-foreground/20
             fixed w-full 2 pt-0 pb-[0.8rem] pr-4 border-b-2 border-black z-[100] bottom-0 items-center content-stretch gap-0
              ">
            <footer className="grid grid-cols-3 justify-center h-6 px-2 gap-0 items-center">
                
                <motion.div className='flex gap-2 col-start-1'>
                    <WebsocketIndicator />
                </motion.div>

                <motion.div className="hidden min-[640px]:flex -translate-x-6 sm:translate-x-0 text-nowrap text-xs justify-center items-center">
                    <Copyright className='p-[0.2rem] sm:p-[0.35rem]'/> Inscribing Atlantis 2024
                </motion.div>

                <motion.div
                    variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
                    initial='hidden'
                    animate='visible'
                    key='nav-controls'
                    className="col-start-3 w-fit justify-self-end flex flex-row gap-4 items-center"
                >
                    <Socials />
                    <Settings />
                </motion.div>
            </footer>
        </motion.div>
    );
}
