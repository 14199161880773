import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogDescription,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import LeatherIcon from "./LeatherIcon";
import XverseIcon from "./XverseIcon";
import { Button } from '../ui/button';
import { useConnectWalletMutation } from '@/queries/account';
import { useAccountStore } from '@/stores/accountStore';
import { useSettingsStore } from '@/stores/settingsStore';
import { Wallet } from '@/schemas/bitcoin';

interface WalletProvider {
    name: string;
    wallet: Wallet;
    icon: React.ReactNode;
    isAvailable: boolean;
}

const useWalletProviders = (): WalletProvider[] => {
    const [providers, setProviders] = useState<WalletProvider[]>([
        { name: 'Xverse', wallet: 'xverse', icon: <XverseIcon size={48} />, isAvailable: false },
        { name: 'Leather', wallet: 'leather', icon: <LeatherIcon size={48} />, isAvailable: false },
    ]);

    useEffect(() => {
        if (typeof window !== 'undefined' && window.btc_providers) {
            const providers = window.btc_providers;
            setProviders(prevProviders =>
                prevProviders.map(provider => ({
                    ...provider,
                    isAvailable: providers.some(p => p.name.includes(provider.name))
                }))
            );
        }
    }, []);

    return providers;
}

export const ConnectDialog = () => {
    const walletProviders = useWalletProviders();
    const connectMutation = useConnectWalletMutation();
    const network = useAccountStore(state => state.network);

    const isOpen = useSettingsStore(state => state.isWalletDialogOpen);
    const closeDialog = useSettingsStore(state => state.closeWalletDialog);

    return (
        <Dialog open={isOpen} onOpenChange={(open) => !open && closeDialog()}>
            <DialogContent className='z-[1000]' overlayClassName='z-[900]'>
                <DialogHeader>
                    <DialogTitle>Connect Wallet</DialogTitle>
                </DialogHeader>
                <DialogDescription asChild>
                    <ul className="flex flex-col gap-2">
                        {walletProviders.map((provider) => (
                            <li key={provider.name} className={provider.isAvailable ? 'enabled' : 'disabled'}>
                                <Button
                                    variant={'outline'}
                                    disabled={!provider.isAvailable}
                                    onClick={() => {
                                        if (!network) return;
                                        connectMutation.mutate({ network: network, wallet: provider.wallet });
                                        closeDialog();
                                    }}
                                    className="w-full h-20 justify-start gap-2 text-lg text-primary"
                                >
                                    <div>
                                        {provider.icon}
                                    </div>
                                    <span>{provider.name}</span>
                                </Button>
                            </li>
                        ))}
                    </ul>
                </DialogDescription>
            </DialogContent>
        </Dialog>
    );
};

export default ConnectDialog;