import { useSettingsStore } from '@/stores/settingsStore';
import { createContext, ReactNode, useCallback, useContext, useEffect, useRef } from 'react';
import { useSound } from 'use-sound';

export interface SfxState {
  playBlockHeight: () => void;
  playNewBid: () => void;
  playOutBid: () => void;
  playOutBidBack: () => void;
}

const SfxContext = createContext<SfxState | undefined>(undefined);

export const useSfx = () => {
  const context = useContext(SfxContext);
  if (context === undefined) {
    throw new Error('useSfx must be used within a SfxProvider');
  }
  return context;
};

const spriteMap: Record<string, [number, number]> = {
  'blockHeight': [0, 1500],
  'newBid': [1800, 1650],
  'outBid': [3500, 1600],
  'outBidBack': [5090, 1000],
};

interface SfxProviderProps {
  children: ReactNode;
}

export const SfxProvider = ({ children }: SfxProviderProps) => {
  const isAudioEnabled = useSettingsStore(state => state.isAudioEnabled);
  const hasInteractedRef = useRef(false);

  const [play, { sound }] = useSound('/ephemera-sfx.mp3', {
    sprite: spriteMap,
    volume: 0.05,
    interrupt: true,
  });

  const playSound = useCallback((id: string) => {
    if (isAudioEnabled)
    play({ id })
  }, [play, isAudioEnabled]);

  const playBlockHeight = useCallback(() => playSound('blockHeight'), [playSound]);
  const playNewBid = useCallback(() => playSound('newBid'), [playSound]);
  const playOutBid = useCallback(() => playSound('outBid'), [playSound]);
  const playOutBidBack = useCallback(() => playSound('outBidBack'), [playSound]);

  useEffect(() => {
    const initVol = () => {
      if (sound && !hasInteractedRef.current) {
        hasInteractedRef.current = true;
        sound.volume(0.1)
      }
    };

    const initSound = async () => {
      if (typeof window !== 'undefined' && !hasInteractedRef.current) {
        try {
          await new Promise(resolve => setTimeout(resolve, 1000));
          document.body.addEventListener('click', initVol);
          document.body.addEventListener('keydown', initVol);
        } catch (e) {
          console.error(e);
        }
      }
    }

    initSound();
    return () => {
      window.removeEventListener('click', initVol);
      window.removeEventListener('keydown', initVol);
    };
  }, [sound]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      (window as any).playSounds = {
        playBlockHeight,
        playNewBid,
        playOutBid,
        playOutBidBack,
      };
    }
  }, [playBlockHeight, playNewBid, playOutBid, playOutBidBack]);

  const value = {
    playBlockHeight,
    playNewBid,
    playOutBid,
    playOutBidBack,
  };
  return <SfxContext.Provider value={value}>{children}</SfxContext.Provider>;
};
