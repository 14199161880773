const LeatherIcon = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 128 128"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <rect width="128" height="128" fill="#12100F" rx="26.839" />
    <path
      fill="#F5F1ED"
      d="M74.917 52.711c7.56-1.17 18.492-9.13 18.492-15.335 0-1.873-1.512-3.16-3.722-3.16-4.187 0-11.28 6.32-14.77 18.495zM39.911 83.5c-9.885 0-10.7 9.833-.814 9.833 4.42 0 9.77-1.756 12.56-4.916-4.07-3.512-7.443-4.917-11.746-4.917zm62.918-4.214c.581 16.506-7.792 25.754-21.98 25.754-8.374 0-12.56-3.161-21.516-9.014-4.652 5.151-13.49 9.014-20.818 9.014-25.236 0-24.19-32.193 1.512-32.193 5.35 0 9.886 1.405 15.7 5.034l3.839-13.462c-15.817-4.333-23.726-16.507-15.933-33.95h12.56c-6.978 11.59-2.21 21.189 6.629 22.242C67.59 35.737 77.825 22.51 91.432 22.51c7.675 0 13.723 5.034 13.723 14.165 0 14.633-19.073 26.573-33.494 27.744L65.73 85.372c6.745 7.843 25.469 15.452 25.469-6.087h11.63z"
    />
  </svg>
);

export default LeatherIcon;
