import { useSfx } from "@/contexts/SfxContext";
import { RankedBid } from "@/schemas/auction";
import { useAccountStore } from "@/stores/accountStore";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

export const GlobalSoundHandler = () => {
    const blockInfoQuery = useQuery({ queryKey: ['blockInfo'] });
    const newBidQuery = useQuery<RankedBid>({ queryKey: ['newBid'] });
    const ordinalAddress = useAccountStore(state => state.ordinalAddress);
    const { playBlockHeight, playNewBid, playOutBidBack } = useSfx();

    useEffect(() => {
        if (blockInfoQuery && blockInfoQuery.data) {
            playBlockHeight();
        }
    }, [blockInfoQuery.data]);

    useEffect(() => {
        if (newBidQuery && newBidQuery.data) {

            if (ordinalAddress) {
                if (newBidQuery.data.ordinal_address === ordinalAddress) {
                    // TODO: is it always appropriate to playOutBidBack here?
                    playOutBidBack();
                    return;
                } else {
                    // TODO: Check if the bid replaced a bid by the current user
                    // playOutBid();
                }
            }

            playNewBid();
        }
    }, [newBidQuery.data]);

    return null;
}
