import { useSettingsStore } from '@/stores/settingsStore';
import { motion } from 'framer-motion';
import { Volume2, VolumeX } from 'lucide-react';
import { Switch } from '../ui/switch';

const variants = { hidden: { opacity: 0 }, visible: { opacity: 1 } };

export function AudioToggle() {
  const isAudioEnabled = useSettingsStore(state => state.isAudioEnabled);
  const toggleAudioEnabled = useSettingsStore(state => state.toggleAudioEnabled);
  const baseClass = 'transition-all duration-200 w-3';
  const onColor = 'stroke-gray-200';
  const offColor = 'stroke-gray-700';
  return (
    <motion.div
      layout
      layoutId='audio-toggle'
      layoutScroll={false}
      key='audio-toggle'
      variants={variants}
      className='flex flex-col items-center gap-0'
    >
      {isAudioEnabled !== null && (
        <>
          <Switch checked={isAudioEnabled} onCheckedChange={toggleAudioEnabled} className='w-12 h-6' />
          <div className='flex items-center gap-2 absolute pointer-events-none'>
            <VolumeX className={`${baseClass} ${!isAudioEnabled ? onColor : offColor}`} size={20} />
            <Volume2 className={`${baseClass} ${isAudioEnabled ? onColor : offColor}`}size={20} />
          </div>
        </>
      )}
    </motion.div >
  );
}